<script lang="ts" setup>
const { isSplashScreenVisible } = useSplashScreen()

onMounted(() => {
    setTimeout(() => {
        isSplashScreenVisible.value = false
    }, 500)
})

const { hideSplashScreen } = useRuntimeConfig().public
</script>

<template>
    <Transition name="slide-up">
        <div v-if="isSplashScreenVisible && !hideSplashScreen" fixed z-9999 inset-0 bg-white flex items-end justify-end shadow-2xl h-100dvh>
            <div
                text-dark-500 text-20 p-20
                flex items-center
            >
                <div h-.85em w-.85em b="6px dark-500" mr-.25em />
                <span
                    v-for="letter, i in 'Tela'" :key="i"
                    :style="{
                        animationDelay: `${(i + 1) * 0.15}s`,
                    }"
                    class="letter"
                    mr--.8
                    :class="[i === 0 && 'mr--3']"
                >
                    {{ letter }}
                </span>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.letter {
    animation: slide-from-bottom 0.5s cubic-bezier(0.05, 1.09, 0.56, 0.97);
    position: relative;
    opacity: 0;
    font-family: Articulat;
    animation-fill-mode: forwards;
}

@keyframes slide-from-bottom {
    0% {
        opacity: 0;
        top: 40px;
    }
    100% {
        opacity: 1;
        top: 0px;
    }
}
</style>
